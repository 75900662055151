import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { DecoratedTask } from '@witmetrics/api-client';
import { setActiveAccount } from './activeAccountSlice';
import {
  addUnisonProjectTask,
  setUnisonProjectTasks,
} from './unisonProjectTasksSlice';
import { mapToKey } from '@/utils/arrays';

type TasksState = {
  byID: Record<string, DecoratedTask>;
};

const initialState = {
  byID: {},
} satisfies TasksState as TasksState;

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    setTasks: (state, action: PayloadAction<DecoratedTask[]>) => {
      return getNextState(state, action.payload);
    },
    addTask: (state, action: PayloadAction<DecoratedTask>) => {
      return getNextState(state, [action.payload]);
    },
    updateTask: (state, action: PayloadAction<DecoratedTask>) => {
      return getNextState(state, [action.payload]);
    },
    deleteTask: (state, action: PayloadAction<{ taskID: number }>) => {
      delete state.byID[action.payload.taskID];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => {
        return { byID: {} };
      })
      .addCase(setUnisonProjectTasks, (state, action) => {
        return getNextState(state, mapToKey(action.payload, 'task'));
      })
      .addCase(addUnisonProjectTask, (state, action) => {
        return getNextState(state, [action.payload.task]);
      })
      .addDefaultCase(() => {});
  },
});

function getNextState(state: TasksState, tasks: DecoratedTask[]) {
  let byID = { ...state.byID };
  tasks.forEach((t) => {
    byID[`${t.id}`] = t;
  });
  return { byID };
}

export const { setTasks, addTask, updateTask, deleteTask } = tasksSlice.actions;

export default tasksSlice.reducer;
