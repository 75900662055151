import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { UndecoratedFileVersion } from '@witmetrics/api-client';
import { setActiveAccount } from './activeAccountSlice';
import { addToIDArray } from '@/store/utils/stateFormatting';

type FileVersionsState = {
  byID: Record<string, UndecoratedFileVersion>;
  byFileID: Record<string, string[]>;
};

const initialState = {
  byID: {},
  byFileID: {},
} satisfies FileVersionsState as FileVersionsState;

export const fileVersionsSlice = createSlice({
  name: 'fileVersions',
  initialState,
  reducers: {
    setFileVersions: (
      state,
      action: PayloadAction<UndecoratedFileVersion[]>
    ) => {
      return getNextState(state, action.payload);
    },
    addFileVersion: (state, action: PayloadAction<UndecoratedFileVersion>) => {
      return getNextState(state, [action.payload]);
    },
    updateFileVersion: (
      state,
      action: PayloadAction<UndecoratedFileVersion>
    ) => {
      return getNextState(state, [action.payload]);
    },
    deleteFileVersion: (
      state,
      action: PayloadAction<{ fileVersionID: number }>
    ) => {
      const key = `${action.payload.fileVersionID}`;
      if (!state.byID[key]) return state;
      const { fileID } = state.byID[key];
      if (state.byFileID[fileID]) {
        state.byFileID[fileID] = state.byFileID[fileID].filter(
          (id) => id !== key
        );
      }
      delete state.byID[key];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => {
        return { byID: {}, byFileID: {} };
      })
      .addDefaultCase(() => {});
  },
});

function getNextState(
  state: FileVersionsState,
  fileVersions: UndecoratedFileVersion[]
) {
  let byID = { ...state.byID };
  let byFileID = { ...state.byFileID };
  fileVersions.forEach((fv) => {
    const key = `${fv.id}`;
    byID[key] = fv;
    byFileID[fv.fileID] = addToIDArray(byFileID[fv.fileID], key);
  });
  return { byID, byFileID };
}

export const {
  setFileVersions,
  addFileVersion,
  updateFileVersion,
  deleteFileVersion,
} = fileVersionsSlice.actions;

export default fileVersionsSlice.reducer;
