import { io, type Socket as SocketIO } from 'socket.io-client';
import { getAPIURL } from '@/api/getURL';
import type { Dictionary } from '@/types';
import { type AppDispatch } from '@/store/store';
import { isEmpty } from '@/utils/arrays';
import { SOCKET_EVENTS } from '@/constants/socketEvents';
import {
  addChecklist,
  deleteChecklist,
  updateChecklist,
} from '@/store/slices/checklistsSlice';
import {
  type DecoratedChecklist,
  type DecoratedChecklistItem,
  type DecoratedConversation,
  type DecoratedConversationMessage,
  type DecoratedConversationUser,
  type DecoratedFileCollection,
  type DecoratedFileCollectionFile,
  type DecoratedUnisonProject,
  type DecoratedUnisonProjectChecklist,
  DecoratedUnisonProjectConversation,
  FetchUnisonProjectFileCollectionResponse,
  UnauthorizedError,
  type UndecoratedFile,
  type UndecoratedFileVersion,
} from '@witmetrics/api-client';
import {
  addChecklistItem,
  deleteChecklistItem,
  updateChecklistItem,
} from '@/store/slices/checklistItemsSlice';
import {
  addConversation,
  deleteConversation,
  updateConversation,
} from '@/store/slices/conversationsSlice';
import {
  addConversationMessage,
  deleteConversationMessage,
  updateConversationMessage,
} from '@/store/slices/conversationMessagesSlice';
import {
  deleteConversationUser,
  setConversationUsers,
  updateConversationUser,
} from '@/store/slices/conversationUsersSlice';
import {
  addFileCollection,
  deleteFileCollection,
  updateFileCollection,
} from '@/store/slices/fileCollectionsSlice';
import {
  addFileCollectionFile,
  deleteFileCollectionFile,
} from '@/store/slices/fileCollectionFilesSlice';
import { addFile, deleteFile, updateFile } from '@/store/slices/filesSlice';
import {
  addFileVersion,
  deleteFileVersion,
  updateFileVersion,
} from '@/store/slices/fileVersionsSlice';
import {
  addUnisonProject,
  deleteUnisonProject,
  updateUnisonProject,
} from '@/store/slices/unisonProjectsSlice';
import { addUnisonProjectChecklist } from '@/store/slices/unisonProjectChecklistsSlice';
import { addUnisonProjectConversation } from '@/store/slices/unisonProjectConversationsSlice';
import { addUnisonProjectFileCollection } from '@/store/slices/unisonProjectFileCollectionsSlice';

const {
  ADD_CONVERSATION_USERS,
  CREATE_CHECKLIST,
  CREATE_CHECKLIST_ITEM,
  CREATE_CONVERSATION,
  CREATE_CONVERSATION_MESSAGE,
  CREATE_FILE,
  CREATE_FILE_COLLECTION,
  CREATE_FILE_COLLECTION_FILE,
  CREATE_FILE_VERSION,
  CREATE_UNISON_PROJECT,
  CREATE_UNISON_PROJECT_CHECKLIST,
  CREATE_UNISON_PROJECT_CONVERSATION,
  CREATE_UNISON_PROJECT_FILE_COLLECTION,
  DELETE_CHECKLIST,
  DELETE_CHECKLIST_ITEM,
  DELETE_CONVERSATION,
  DELETE_CONVERSATION_MESSAGE,
  DELETE_CONVERSATION_USER,
  DELETE_FILE,
  DELETE_FILE_COLLECTION,
  DELETE_FILE_VERSION,
  DELETE_UNISON_PROJECT,
  UPDATE_CHECKLIST,
  UPDATE_CHECKLIST_ITEM,
  UPDATE_CONVERSATION,
  UPDATE_CONVERSATION_MESSAGE,
  UPDATE_CONVERSATION_USER,
  UPDATE_FILE,
  UPDATE_FILE_COLLECTION,
  UPDATE_FILE_COLLECTION_FILE,
  UPDATE_FILE_VERSION,
  UPDATE_UNISON_PROJECT,
} = SOCKET_EVENTS;

export const ACTIVE_WARNING = 'No active socket connection';

export type EventError = {
  type: string;
  message: string;
};

export type SocketSubscription = {
  endpoint: string;
  callback: SocketEventCallback;
  data?: any;
};

export type JoinSettings = {
  endpoint: string;
  data?: Dictionary<any>;
  callback?: SocketEventCallback;
};

export type SocketEventCallback = (data: any, error?: EventError) => void;

export type ApiErrorCallback = () => any;

export type Subscribe = (
  subscriptions: SocketSubscription | SocketSubscription[]
) => void;

export type Unsubscribe = (endpoint: string) => void;

export type Join = (joinSettings: JoinSettings) => void;

export type Leave = (endpoint: string) => void;

export type Send = (endpoint: string, data: any) => void;

export type SubscriptionQueueItem = {
  endpoint: string;
  callback: SocketEventCallback;
  data?: any;
};

export type JoinQueueItem = Omit<SubscriptionQueueItem, 'callback'> & {
  callback?: SocketEventCallback;
};

export type Socket = {
  subscribe: Subscribe;
  unsubscribe: Unsubscribe;
  join: Join;
  leave: Leave;
  send: Send;
  socket: SocketIO;
};

export type SubscriptionManager = Dictionary<{
  callback: SocketEventCallback;
  data?: any;
}>;

export type JoinManager = Dictionary<{
  callback?: SocketEventCallback;
  data?: any;
}>;

export function connectSocket(onApiError: (err: any) => void) {
  return Socket(getAPIURL(), onApiError);
}

function Socket(url: string, onApiError: (err: any) => void) {
  const socket = io(url, { withCredentials: true });
  let isSubscriptionQueueLocked = false;
  let isJoinQueueLocked = false;
  let subscriptionQueue: SubscriptionQueueItem[] = [];
  let subscriptionManager: SubscriptionManager = {};
  let joinQueue: JoinQueueItem[] = [];
  let joinManager: JoinManager = {};

  socket.on('connect', () => {
    // Reconnect any subscriptions that were broken on disconnect
    if (!isEmpty(subscriptionManager)) placeSubscriptionManagerInQueue();
    if (!isEmpty(joinManager)) placeJoinManagerInQueue();
  });

  socket.on('connect_error', ({ message }) => {
    if (message === 'Unauthorized') {
      onApiError(new UnauthorizedError(message, message));
    } else {
      console.warn(message);
    }
  });

  function placeSubscriptionManagerInQueue() {
    // Move each subscription from the manager into the queue
    addToSubscriptionQueue(
      Object.keys(subscriptionManager).map((key) => ({
        endpoint: key,
        callback: subscriptionManager[key].callback,
        data: subscriptionManager[key].data,
      }))
    );
    subscriptionManager = {};
  }

  function placeJoinManagerInQueue() {
    // Move each join from the manager into the queue
    addToJoinQueue(
      Object.keys(joinManager).map((key) => ({
        endpoint: key,
        callback: joinManager[key].callback,
        data: joinManager[key].data,
      }))
    );
    joinManager = {};
  }

  function addToSubscriptionQueue(subscriptions: SocketSubscription[]) {
    subscriptionQueue.push(...subscriptions);
    if (!isSubscriptionQueueLocked) runSubscriptionQueue();
  }

  function addToJoinQueue(joins: JoinSettings[]) {
    joinQueue.push(...joins);
    if (!isJoinQueueLocked) runJoinQueue();
  }

  function runSubscriptionQueue() {
    if (isEmpty(subscriptionQueue)) {
      isSubscriptionQueueLocked = false;
      return;
    }
    isSubscriptionQueueLocked = true;
    let queueItem = subscriptionQueue.shift();
    if (queueItem) {
      processSubscriptionQueueItem(queueItem)
        .then(runSubscriptionQueue)
        .catch((err: any) => {
          throw err;
        });
    }
  }

  function runJoinQueue() {
    if (isEmpty(joinQueue)) {
      isJoinQueueLocked = false;
      return;
    }
    isJoinQueueLocked = true;
    let queueItem = joinQueue.shift();
    if (queueItem) {
      processJoinQueueItem(queueItem)
        .then(runJoinQueue)
        .catch((err: any) => {
          throw err;
        });
    }
  }

  function processSubscriptionQueueItem(queueItem: SubscriptionQueueItem) {
    const { endpoint, callback, data } = queueItem;
    return new Promise((resolve) => {
      socket.on(
        'subscribe',
        (endpoint: string, data: any, error?: EventError) => {
          socket.off('subscribe');
          if (error && error.type.length > 0) {
            warnJoinError(endpoint, error);
            resolve(endpoint); // Resolve anyways so queue can continue
          } else {
            subscriptionManager[endpoint] = { callback, data };
            socket.off(endpoint);
            socket.on(endpoint, (update) => {
              if (subscriptionManager[endpoint]?.callback instanceof Function) {
                try {
                  subscriptionManager[endpoint].callback(update);
                } catch (err) {
                  warnRunError(endpoint, subscriptionManager);
                }
              } else {
                warnMissingError(endpoint, subscriptionManager);
              }
            });
            resolve(endpoint);
          }
        }
      );
      socket.emit('subscribe', endpoint, data);
    });
  }

  function processJoinQueueItem(queueItem: JoinQueueItem) {
    const { endpoint, data, callback } = queueItem;
    return new Promise((resolve) => {
      socket.on('join', (endpoint: string, data: any, error?: EventError) => {
        socket.off('join');
        if (error && error.type.length > 0) {
          warnJoinError(endpoint, error);
          if (callback) callback(data, error);
          resolve(endpoint); // Resolve anyways so queue can continue
        } else {
          joinManager[endpoint] = { callback, data };
          socket.off(endpoint);
          if (callback) {
            try {
              callback(data, error);
            } catch (err) {
              warnRunError(endpoint, joinManager);
            }
          }
          resolve(endpoint);
        }
      });
      socket.emit('join', endpoint, data);
    });
  }

  function subscribe(subscription: SocketSubscription | SocketSubscription[]) {
    if (Array.isArray(subscription)) {
      // Bulk add the new subscriptions (and clear out stale versions)
      subscription.forEach((s) => checkExistingSubscription(s.endpoint));
      addToSubscriptionQueue(subscription);
    } else {
      checkExistingSubscription(subscription.endpoint);
      addToSubscriptionQueue([subscription]);
    }
  }

  function checkExistingSubscription(endpoint: string) {
    if (subscriptionManager[endpoint]) {
      warnSubscribeError(endpoint);
      socket.off(endpoint);
    }
  }

  function unsubscribe(endpoint: string) {
    socket.off(endpoint);
    socket.emit('unsubscribe', endpoint);
    delete subscriptionManager[endpoint];
  }

  function join(joinSettings: JoinSettings | JoinSettings[]) {
    if (Array.isArray(joinSettings)) addToJoinQueue(joinSettings);
    else addToJoinQueue([joinSettings]);
  }

  function leave(endpoint: string) {
    socket.emit('leave', { endpoint });
    delete joinManager[endpoint];
  }

  function send(endpoint: string, data: any) {
    return socket.emit(endpoint, data);
  }

  return { subscribe, unsubscribe, join, leave, send, socket };
}

function warnJoinError(
  endpoint: string,
  error: { type: string; message: string }
) {
  return console.warn(
    `${error.type}: Error joining socket "${endpoint}". ${error.message}`
  );
}

function warnRunError(
  endpoint: string,
  manager: SubscriptionManager | JoinManager
) {
  return console.warn(
    `Unable to run socket endpoint "${endpoint}". It was either not found in ${JSON.stringify(
      Object.keys(manager)
    )} or is not a valid function.`
  );
}

function warnMissingError(
  endpoint: string,
  manager: SubscriptionManager | JoinManager
) {
  return console.warn(
    `Socket endpoint "${endpoint}" was either not found in ${JSON.stringify(
      Object.keys(manager)
    )} or is not a valid function`
  );
}

function warnSubscribeError(endpoint: string) {
  return console.warn(
    `Existing subscription for "${endpoint}". Existing subscription will be overwritten.`
  );
}

export function initiateDispatchSockets(
  dispatch: AppDispatch,
  subscribe: Subscribe
) {
  subscribeChecklists(dispatch, subscribe);
  subscribeChecklistItems(dispatch, subscribe);
  subscribeConversations(dispatch, subscribe);
  subscribeConversationMessages(dispatch, subscribe);
  subscribeConversationUsers(dispatch, subscribe);
  subscribeFileCollections(dispatch, subscribe);
  subscribeFileCollectionFiles(dispatch, subscribe);
  subscribeFiles(dispatch, subscribe);
  subscribeFileVersions(dispatch, subscribe);
  subscribeUnisonProjects(dispatch, subscribe);
  subscribeUnisonProjectFileCollections(dispatch, subscribe);
  subscribeUnisonProjectChecklists(dispatch, subscribe);
  subscribeUnisonProjectConversations(dispatch, subscribe);
}

function subscribeChecklists(dispatch: AppDispatch, subscribe: Subscribe) {
  subscribe({
    endpoint: CREATE_CHECKLIST,
    callback: ({ checklist }: { checklist: DecoratedChecklist }) =>
      dispatch(addChecklist(checklist)),
  });
  subscribe({
    endpoint: UPDATE_CHECKLIST,
    callback: ({ checklist }: { checklist: DecoratedChecklist }) =>
      dispatch(updateChecklist(checklist)),
  });
  subscribe({
    endpoint: DELETE_CHECKLIST,
    callback: ({ checklistID }: { checklistID: number }) => {
      dispatch(deleteChecklist({ checklistID }));
    },
  });
}

function subscribeChecklistItems(dispatch: AppDispatch, subscribe: Subscribe) {
  subscribe({
    endpoint: CREATE_CHECKLIST_ITEM,
    callback: ({
      checklist,
      item,
    }: {
      checklist: DecoratedChecklist;
      item: DecoratedChecklistItem;
    }) => {
      dispatch(updateChecklist(checklist));
      dispatch(addChecklistItem(item));
    },
  });
  subscribe({
    endpoint: UPDATE_CHECKLIST_ITEM,
    callback: ({ checklistItem }: { checklistItem: DecoratedChecklistItem }) =>
      dispatch(updateChecklistItem(checklistItem)),
  });
  subscribe({
    endpoint: DELETE_CHECKLIST_ITEM,
    callback: ({
      itemID,
      checklist,
    }: {
      itemID: number;
      checklist: DecoratedChecklist;
    }) => {
      dispatch(deleteChecklistItem({ itemID }));
      dispatch(updateChecklist(checklist));
    },
  });
}

function subscribeConversations(dispatch: AppDispatch, subscribe: Subscribe) {
  subscribe({
    endpoint: CREATE_CONVERSATION,
    callback: ({ conversation }: { conversation: DecoratedConversation }) =>
      dispatch(addConversation(conversation)),
  });
  subscribe({
    endpoint: UPDATE_CONVERSATION,
    callback: ({ conversation }: { conversation: DecoratedConversation }) =>
      dispatch(updateConversation(conversation)),
  });
  subscribe({
    endpoint: DELETE_CONVERSATION,
    callback: ({ conversationID }: { conversationID: number }) => {
      dispatch(deleteConversation({ conversationID }));
    },
  });
}

function subscribeConversationMessages(
  dispatch: AppDispatch,
  subscribe: Subscribe
) {
  subscribe({
    endpoint: CREATE_CONVERSATION_MESSAGE,
    callback: ({
      conversationMessage,
    }: {
      conversationMessage: DecoratedConversationMessage;
    }) => dispatch(addConversationMessage(conversationMessage)),
  });
  subscribe({
    endpoint: UPDATE_CONVERSATION_MESSAGE,
    callback: ({
      conversationMessage,
    }: {
      conversationMessage: DecoratedConversationMessage;
    }) => dispatch(updateConversationMessage(conversationMessage)),
  });
  subscribe({
    endpoint: DELETE_CONVERSATION_MESSAGE,
    callback: ({
      conversationMessageID,
    }: {
      conversationMessageID: number;
    }) => {
      dispatch(deleteConversationMessage({ conversationMessageID }));
    },
  });
}

function subscribeConversationUsers(
  dispatch: AppDispatch,
  subscribe: Subscribe
) {
  subscribe({
    endpoint: ADD_CONVERSATION_USERS,
    callback: ({
      conversationUsers,
    }: {
      conversationUsers: DecoratedConversationUser[];
    }) => dispatch(setConversationUsers(conversationUsers)),
  });
  subscribe({
    endpoint: UPDATE_CONVERSATION_USER,
    callback: ({
      conversationUser,
    }: {
      conversationUser: DecoratedConversationUser;
    }) => dispatch(updateConversationUser(conversationUser)),
  });
  subscribe({
    endpoint: DELETE_CONVERSATION_USER,
    callback: ({
      conversationID,
      userID,
    }: {
      conversationID: number;
      userID: number;
    }) => {
      dispatch(deleteConversationUser({ conversationID, userID }));
    },
  });
}

function subscribeFileCollections(dispatch: AppDispatch, subscribe: Subscribe) {
  subscribe({
    endpoint: CREATE_FILE_COLLECTION,
    callback: ({
      fileCollection,
    }: {
      fileCollection: DecoratedFileCollection;
    }) => dispatch(addFileCollection(fileCollection)),
  });
  subscribe({
    endpoint: UPDATE_FILE_COLLECTION,
    callback: ({
      fileCollection,
    }: {
      fileCollection: DecoratedFileCollection;
    }) => dispatch(updateFileCollection(fileCollection)),
  });
  subscribe({
    endpoint: DELETE_FILE_COLLECTION,
    callback: ({ fileCollectionID }: { fileCollectionID: number }) => {
      dispatch(deleteFileCollection({ fileCollectionID }));
    },
  });
}

function subscribeFileCollectionFiles(
  dispatch: AppDispatch,
  subscribe: Subscribe
) {
  subscribe({
    endpoint: CREATE_FILE_COLLECTION_FILE,
    callback: ({
      fileCollectionFile,
    }: {
      fileCollectionFile: DecoratedFileCollectionFile;
    }) => dispatch(addFileCollectionFile(fileCollectionFile)),
  });
  subscribe({
    endpoint: UPDATE_FILE_COLLECTION_FILE,
    callback: ({
      previousFileCollectionID,
      fileCollectionFile,
    }: {
      previousFileCollectionID: number;
      fileCollectionFile: DecoratedFileCollectionFile;
    }) => {
      dispatch(
        deleteFileCollectionFile({
          fileCollectionID: previousFileCollectionID,
          fileID: fileCollectionFile.fileID,
        })
      );
      dispatch(addFileCollectionFile(fileCollectionFile));
    },
  });
}

function subscribeFiles(dispatch: AppDispatch, subscribe: Subscribe) {
  subscribe({
    endpoint: CREATE_FILE,
    callback: ({ file }: { file: UndecoratedFile }) => dispatch(addFile(file)),
  });
  subscribe({
    endpoint: UPDATE_FILE,
    callback: ({ file }: { file: UndecoratedFile }) =>
      dispatch(updateFile(file)),
  });
  subscribe({
    endpoint: DELETE_FILE,
    callback: ({ fileID }: { fileID: number }) => {
      dispatch(deleteFile({ fileID }));
    },
  });
}

function subscribeFileVersions(dispatch: AppDispatch, subscribe: Subscribe) {
  subscribe({
    endpoint: CREATE_FILE_VERSION,
    callback: ({ fileVersion }: { fileVersion: UndecoratedFileVersion }) =>
      dispatch(addFileVersion(fileVersion)),
  });
  subscribe({
    endpoint: UPDATE_FILE_VERSION,
    callback: ({ fileVersion }: { fileVersion: UndecoratedFileVersion }) =>
      dispatch(updateFileVersion(fileVersion)),
  });
  subscribe({
    endpoint: DELETE_FILE_VERSION,
    callback: ({ fileVersionID }: { fileVersionID: number }) => {
      dispatch(deleteFileVersion({ fileVersionID }));
    },
  });
}

function subscribeUnisonProjects(dispatch: AppDispatch, subscribe: Subscribe) {
  subscribe({
    endpoint: CREATE_UNISON_PROJECT,
    callback: ({ unisonProject }: { unisonProject: DecoratedUnisonProject }) =>
      dispatch(addUnisonProject(unisonProject)),
  });
  subscribe({
    endpoint: UPDATE_UNISON_PROJECT,
    callback: ({ unisonProject }: { unisonProject: DecoratedUnisonProject }) =>
      dispatch(updateUnisonProject(unisonProject)),
  });
  subscribe({
    endpoint: DELETE_UNISON_PROJECT,
    callback: ({ projectID }: { projectID: number }) => {
      dispatch(deleteUnisonProject({ projectID }));
    },
  });
}

function subscribeUnisonProjectFileCollections(
  dispatch: AppDispatch,
  subscribe: Subscribe
) {
  subscribe({
    endpoint: CREATE_UNISON_PROJECT_FILE_COLLECTION,
    callback: ({
      unisonProjectFileCollection,
    }: {
      unisonProjectFileCollection: FetchUnisonProjectFileCollectionResponse;
    }) => dispatch(addUnisonProjectFileCollection(unisonProjectFileCollection)),
  });
}

function subscribeUnisonProjectChecklists(
  dispatch: AppDispatch,
  subscribe: Subscribe
) {
  subscribe({
    endpoint: CREATE_UNISON_PROJECT_CHECKLIST,
    callback: ({
      unisonProjectChecklist,
    }: {
      unisonProjectChecklist: DecoratedUnisonProjectChecklist;
    }) => dispatch(addUnisonProjectChecklist(unisonProjectChecklist)),
  });
}

function subscribeUnisonProjectConversations(
  dispatch: AppDispatch,
  subscribe: Subscribe
) {
  subscribe({
    endpoint: CREATE_UNISON_PROJECT_CONVERSATION,
    callback: ({
      unisonProjectConversation,
    }: {
      unisonProjectConversation: DecoratedUnisonProjectConversation;
    }) => dispatch(addUnisonProjectConversation(unisonProjectConversation)),
  });
}
