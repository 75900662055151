'use client';
import { useEffect, useState } from 'react';
import {
  getInitialValue,
  getType,
  parseValue,
  StorageType,
  stringifyValue,
} from './utils';

export function useStorage<T>(
  storage: StorageType,
  key: string,
  initialValue: T
) {
  const [value, setValue] = useState<T>(initialValue);

  useEffect(() => {
    // If no initialValue provided, use stored value if present
    try {
      if (
        window[storage].getItem(key) !== null &&
        (initialValue === null || initialValue === undefined)
      ) {
        const storedValue = JSON.parse(window[storage].getItem(key)!);
        const parsedValue = parseValue(storedValue.type, storedValue.value);
        setValue(parsedValue);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    if (value !== null && value !== undefined) storeValue();
  }, [value]);

  const storeValue = () => {
    const type = getType(value);
    try {
      window[storage].setItem(
        key,
        JSON.stringify({ type, value: stringifyValue(type, value) })
      );
    } catch (err) {
      console.error(err);
    }
  };

  const clearValue = () => {
    try {
      window[storage].removeItem(key);
    } catch (err) {
      console.error(err);
    }
  };

  return [value, setValue, { clearValue }] as const;
}

export function useLocalStorage<T>(
  key: string,
  initialValue: T,
  useExisting = false
) {
  return useStorage(
    'localStorage',
    key,
    getInitialValue('localStorage', key, initialValue, useExisting)
  );
}

export function useSessionStorage<T>(
  key: string,
  initialValue: T,
  useExisting = false
) {
  return useStorage(
    'sessionStorage',
    key,
    getInitialValue('sessionStorage', key, initialValue, useExisting)
  );
}
