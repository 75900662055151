'use client';
import {
  createContext,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { UnauthorizedError } from '@witmetrics/api-client';
import { showErrorToaster } from '@/utils/toasters';
import { useActiveAccount } from '@/store/useStore';
import { DIALOGS } from '@/constants/dialogs';
import { useSidebarCollapsed } from '@/hooks/useSidebarCollapsed';
import { usePath } from '@/hooks/usePath';
import type { Dictionary } from '@/types';
import { useSockets } from '@/providers/SocketsProvider';
import { OVERLAYS } from '@/constants/overlays';
import { DEFAULT_DIALOGS, DEFAULT_OVERLAYS, useStoredAccountID } from './utils';

type AppStateValue = {
  isSidebarCollapsed: boolean | undefined;
  activeProjectID: number | null;
  activeCalendarDate: string | null;
  activeDialogs: Dictionary<boolean>;
  activeOverlays: Dictionary<boolean>;
  onToggleSidebarCollapsed: (isCollapsed?: boolean) => void;
  setActiveDialogs: Dispatch<SetStateAction<{ [p: string]: boolean }>>;
  onLogin: () => void;
  onApiError: (err: any, message: string, cb?: Function) => void;
  onClearActiveProject: () => void;
  onSelectProject: (projectID: number, params?: Dictionary<any>) => void;
  onStartTask: (date: string) => void;
  onOpenAgenda: (date: string) => void;
  onToggleUnloadWarning: (activate: boolean) => void;
  onOpenDialog: (dialogName: string) => void;
  onCloseDialog: (dialogName: string) => void;
  onOpenOverlay: (overlayName: string) => void;
  onCloseOverlay: (overlayName: string) => void;
};

const AppStateContext = createContext<AppStateValue>({
  isSidebarCollapsed: false,
  activeProjectID: null,
  activeCalendarDate: null,
  activeDialogs: DEFAULT_DIALOGS,
  activeOverlays: DEFAULT_OVERLAYS,
  onToggleSidebarCollapsed: () => null,
  setActiveDialogs: () => null,
  onLogin: () => null,
  onApiError: () => null,
  onClearActiveProject: () => null,
  onSelectProject: () => null,
  onStartTask: () => null,
  onOpenAgenda: () => null,
  onToggleUnloadWarning: () => null,
  onOpenDialog: () => null,
  onCloseDialog: () => null,
  onOpenOverlay: () => null,
  onCloseOverlay: () => null,
});

export default function AppStateProvider({ children }: PropsWithChildren) {
  const loginCallbacks = useRef<Function[]>([]);
  const activeAccount = useActiveAccount();
  const { setApiErrorCallback } = useSockets();
  const { searchParams, pushParams, removeParams } = usePath();
  const [activeProjectID, setActiveProjectID] = useState<number | null>(null);
  const [activeCalendarDate, setActiveCalendarDate] = useState<string | null>(
    null
  );
  const [activeDialogs, setActiveDialogs] = useState(DEFAULT_DIALOGS);
  const [activeOverlays, setActiveOverlays] = useState(DEFAULT_OVERLAYS);
  const [isSidebarCollapsed, toggleSidebarCollapsed] = useSidebarCollapsed();
  const [storedAccountID, setStoredAccountID] = useStoredAccountID();

  useEffect(() => {
    setApiErrorCallback(handleSocketError);
  }, []);

  useEffect(() => {
    if (!activeAccount) return;
    if (activeAccount.accountID !== storedAccountID) {
      setStoredAccountID(activeAccount.accountID);
    }
  }, [activeAccount]);

  const handleOpenDialog = (dialogName: string) => {
    setActiveDialogs((dialogs) => ({ ...dialogs, [dialogName]: true }));
  };

  const handleCloseDialog = (dialogName: string) => {
    setActiveDialogs((dialogs) => ({ ...dialogs, [dialogName]: false }));
  };

  const handleOpenOverlay = (overlayName: string) => {
    setActiveOverlays((overlays) => ({ ...overlays, [overlayName]: true }));
  };

  const handleCloseOverlay = (overlayName: string) => {
    setActiveOverlays((overlays) => ({ ...overlays, [overlayName]: false }));
    if (overlayName === OVERLAYS.AGENDA) {
      setActiveCalendarDate(null);
    }
  };

  const handleSocketError = () => {
    handleApiError(new UnauthorizedError('', ''), '');
  };

  const handleApiError = (err: any, toasterMessage: string, cb?: Function) => {
    if (err instanceof UnauthorizedError) {
      if (cb) loginCallbacks.current.push(cb);
      if (!activeDialogs[DIALOGS.LOGIN]) handleOpenDialog('login');
    } else {
      showErrorToaster(toasterMessage);
      throw err;
    }
  };

  const handleSetActiveProject = (
    projectID: number,
    params?: Dictionary<any>
  ) => {
    if (projectID === activeProjectID) return null;
    // Optionally include extra params for selecting a tab, conversation, etc.
    setActiveProjectID(projectID);
    pushParams(
      params
        ? { ...params, activeProjectID: projectID }
        : { activeProjectID: projectID }
    );
  };

  const handleClearActiveProject = () => {
    setActiveProjectID(null);
    removeParams([
      'activeProjectID',
      'projectOverlayTab',
      'activeConversationID',
    ]);
  };

  const handleStartTask = (date: string) => {
    setActiveCalendarDate(date);
    handleOpenDialog(DIALOGS.TASK);
  };

  const handleOpenAgenda = (date: string) => {
    setActiveCalendarDate(date);
    handleOpenOverlay(OVERLAYS.AGENDA);
  };

  const handleLogin = () => {
    if (loginCallbacks.current) {
      loginCallbacks.current.forEach((cb) => cb());
    }
    handleCloseDialog(DIALOGS.LOGIN);
    loginCallbacks.current = [];
  };

  const handleToggleSidebar = (isCollapsed?: boolean) => {
    if (isCollapsed === undefined) {
      toggleSidebarCollapsed(!isSidebarCollapsed);
    } else {
      toggleSidebarCollapsed(isCollapsed);
    }
  };

  const handleToggleUnloadWarning = (activate: boolean) => {
    if (activate) handleActivateUnloadWarning();
    else handleDeactivateUnloadWarning();
  };

  const handleActivateUnloadWarning = () => {
    window.removeEventListener('beforeunload', handleUnloadWarning); // Prevent duplicate listeners
    window.addEventListener('beforeunload', handleUnloadWarning);
  };

  const handleDeactivateUnloadWarning = () => {
    window.removeEventListener('beforeunload', handleUnloadWarning);
  };

  const handleUnloadWarning = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = '';
  };

  const contextValue = {
    isSidebarCollapsed,
    activeProjectID,
    activeCalendarDate,
    activeDialogs,
    activeOverlays,
    onToggleSidebarCollapsed: handleToggleSidebar,
    setActiveDialogs,
    onLogin: handleLogin,
    onApiError: handleApiError,
    onClearActiveProject: handleClearActiveProject,
    onSelectProject: handleSetActiveProject,
    onStartTask: handleStartTask,
    onOpenAgenda: handleOpenAgenda,
    onToggleUnloadWarning: handleToggleUnloadWarning,
    onOpenDialog: handleOpenDialog,
    onCloseDialog: handleCloseDialog,
    onOpenOverlay: handleOpenOverlay,
    onCloseOverlay: handleCloseOverlay,
  };

  return (
    <AppStateContext.Provider value={contextValue}>
      {children}
    </AppStateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(AppStateContext);
  if (!context) {
    throw Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
