export const SOCKET_EVENTS = {
  // Socket events
  JOIN: 'join',
  LEAVE: 'leave',
  SUBSCRIBE: 'subscribe',
  UNSUBSCRIBE: 'unsubscribe',
  // Lead events
  CREATE_LEAD: 'lead:create',
  UPDATE_LEAD: 'lead:update',
  DELETE_LEAD: 'lead:delete',
  // Booking events
  CREATE_BOOKING: 'booking:create',
  UPDATE_BOOKING: 'booking:update',
  DELETE_BOOKING: 'booking:delete',
  // Checklist events
  CREATE_CHECKLIST: 'checklist:create',
  UPDATE_CHECKLIST: 'checklist:update',
  DELETE_CHECKLIST: 'checklist:delete',
  // Checklist item events
  CREATE_CHECKLIST_ITEM: 'checklist-item:create',
  UPDATE_CHECKLIST_ITEM: 'checklist-item:update',
  DELETE_CHECKLIST_ITEM: 'checklist-item:delete',
  // Conversation events
  CREATE_CONVERSATION: 'conversation:create',
  UPDATE_CONVERSATION: 'conversation:update',
  DELETE_CONVERSATION: 'conversation:delete',
  OPEN_CONVERSATION: 'conversation:open',
  ADD_CONVERSATION_USERS: 'conversation-users:add',
  UPDATE_CONVERSATION_USER: 'conversation-user:update',
  DELETE_CONVERSATION_USER: 'conversation-user:delete',
  // Conversation message events
  CREATE_CONVERSATION_MESSAGE: 'conversation-message:create',
  UPDATE_CONVERSATION_MESSAGE: 'conversation-message:update',
  DELETE_CONVERSATION_MESSAGE: 'conversation-message:delete',
  // File collection events
  CREATE_FILE_COLLECTION: 'file-collection:create',
  UPDATE_FILE_COLLECTION: 'file-collection:update',
  DELETE_FILE_COLLECTION: 'file-collection:delete',
  CREATE_FILE_COLLECTION_FILE: 'file-collection-file:create',
  UPDATE_FILE_COLLECTION_FILE: 'file-collection-file:update',
  // File events
  CREATE_FILE: 'file:create',
  UPDATE_FILE: 'file:update',
  DELETE_FILE: 'file:delete',
  CREATE_FILE_VERSION: 'file-version:create',
  UPDATE_FILE_VERSION: 'file-version:update',
  DELETE_FILE_VERSION: 'file-version:delete',
  // Unison project events
  CREATE_UNISON_PROJECT: 'unison-project:create',
  UPDATE_UNISON_PROJECT: 'unison-project:update',
  DELETE_UNISON_PROJECT: 'unison-project:delete',
  CREATE_UNISON_PROJECT_FILE_COLLECTION:
    'unison-project-file-collection:create',
  CREATE_UNISON_PROJECT_CHECKLIST: 'unison-project-checklist:create',
  CREATE_UNISON_PROJECT_CONVERSATION: 'unison-project-conversation:create',
  // Task events
  CREATE_TASK: 'task:create',
  UPDATE_TASK: 'task:update',
  DELETE_TASK: 'task:delete',
  // Notification events
  NOTIFICATION: 'notification',
  // Meeting events
  REQUEST_JOIN_MEETING: 'join-meeting:request',
  CANCEL_JOIN_MEETING: 'join-meeting:cancel',
  ACCEPT_JOIN_MEETING: 'join-meeting:accept',
  REJECT_JOIN_MEETING: 'join-meeting:reject',
  JOIN_MEETING_ROLL_CALL: 'join-meeting:roll-call',
  LEAVE_MEETING: 'leave-meeting',
  END_MEETING: 'end-meeting',
} as const;

export const ALL_SOCKET_EVENTS = Object.values(SOCKET_EVENTS);
