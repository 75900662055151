import {
  createContext,
  type PropsWithChildren,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { useDispatch } from '@/store/useStore';
import {
  ACTIVE_WARNING,
  type ApiErrorCallback,
  connectSocket,
  initiateDispatchSockets,
  type Join,
  type Leave,
  type Send,
  type Socket,
  type SocketEventCallback,
  type Subscribe,
  type Unsubscribe,
} from './utils';

export type { SocketEventCallback, Subscribe, Unsubscribe, Join, Leave };

export type SocketsValue = {
  setApiErrorCallback: (cb: ApiErrorCallback) => void;
  subscribe: Subscribe;
  unsubscribe: Unsubscribe;
  join: Join;
  leave: Leave;
  send: Send;
};

const SocketsContext = createContext<SocketsValue>({
  setApiErrorCallback: () => null,
  subscribe: () => null,
  unsubscribe: () => null,
  join: () => null,
  leave: () => null,
  send: () => null,
});

export default function SocketsProvider({ children }: PropsWithChildren) {
  const apiErrorCallback = useRef<() => any>();
  const socket = useRef<Socket | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    handleInitiateSocket();
  }, []);

  const handleInitiateSocket = async () => {
    if (socket.current !== null) {
      // Clear out previous instance to avoid multiple open sockets
      socket.current.socket.disconnect();
    }
    socket.current = connectSocket(handleApiError);
    // @ts-ignore TODO: Drop after testing
    window.socketProvider = socket.current;
    initiateDispatchSockets(dispatch, socket.current!.subscribe);
  };

  const handleSetApiErrorCallback = (callback: () => any) => {
    apiErrorCallback.current = callback;
  };

  const handleApiError = () => {
    if (apiErrorCallback.current) apiErrorCallback.current();
  };

  const handleSubscribe: Subscribe = (subscription) => {
    if (!socket.current) return console.warn(ACTIVE_WARNING);
    socket.current.subscribe(subscription);
  };

  const handleUnsubscribe: Unsubscribe = (endpoint) => {
    if (!socket.current) return console.warn(ACTIVE_WARNING);
    socket.current.unsubscribe(endpoint);
  };

  const handleJoin: Join = (joinSettings) => {
    if (!socket.current) return console.warn(ACTIVE_WARNING);
    socket.current.join(joinSettings);
  };

  const handleLeave: Leave = (endpoint) => {
    if (!socket.current) return console.warn(ACTIVE_WARNING);
    socket.current.leave(endpoint);
  };

  const handleSend: Send = (endpoint, data) => {
    if (!socket.current) return console.warn(ACTIVE_WARNING);
    socket.current.send(endpoint, data);
  };

  const contextValue = {
    setApiErrorCallback: handleSetApiErrorCallback,
    subscribe: handleSubscribe,
    unsubscribe: handleUnsubscribe,
    join: handleJoin,
    leave: handleLeave,
    send: handleSend,
  };

  return (
    <SocketsContext.Provider value={contextValue}>
      {children}
    </SocketsContext.Provider>
  );
}

export function useSockets() {
  const context = useContext(SocketsContext);
  if (!context) {
    throw Error('useSockets must be used within the SocketsProvider');
  }
  return context;
}
