import type { Dictionary } from '@/types';
import { mergeWithoutDuplicates } from '@/utils/arrays';

type ArrayToObjectType<T, F extends string[]> = {
  byID: Dictionary<T>;
} & {
  [K in F[number]]: string[];
};

export function generateEmptyState<T, F extends string[]>(
  additionalFields: F
): ArrayToObjectType<T, F> {
  // const example = generateEmptyState<number, ['byExampleID', 'byUserID']>(['byExampleID', 'byUserID']);
  return {
    byID: {},
    ...additionalFields.reduce(
      (obj, key) => ({ ...obj, [key]: [] }),
      {} as { [K in F[number]]: string[] }
    ),
  };
}

export function generateID(obj: Dictionary<any>, keyStructure: string) {
  const identifier = keyStructure.split('.');
  return identifier.map((id) => obj[id]).join('.');
}

export function addToIDArray<T>(arr: T[] | undefined, id: T) {
  return arr ? mergeWithoutDuplicates<T>(arr, [id]) : [id];
}
