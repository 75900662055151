import { createSlice } from '@reduxjs/toolkit';
import { setCurrentUser } from './currentUserSlice';
import { PREFERENCE_IDS } from '@/constants/preferences';
import {
  DecoratedUserPreference,
  UndecoratedUserPreference,
} from '@witmetrics/api-client';
import { type Identifier } from '../utils/buildState';
import { addToIDArray } from '../utils/stateFormatting';

interface UserPreference extends Omit<UndecoratedUserPreference, 'value'> {
  value: string | number | null | undefined;
}

type UserPreferencesState = {
  byID: Record<string, UserPreference>;
  byUserID: Record<string, string[]>;
};

const initialState = {
  byID: {},
  byUserID: {},
} satisfies UserPreferencesState as UserPreferencesState;

export const userPreferencesSlice = createSlice({
  name: 'userPreferences',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setCurrentUser, (state, action) => {
        const userPreferences =
          action.payload.userPreferences.map(formatUserPreference);
        let byID = { ...state.byID };
        let byUserID = { ...state.byUserID };
        userPreferences.forEach((userPreference) => {
          const key = buildUserPreferenceKey(
            userPreference.userID,
            userPreference.preferenceID
          );
          byID[key] = userPreference;
          byUserID[userPreference.userID] = addToIDArray(
            byUserID[userPreference.userID],
            key
          );
        });
        state.byID = byID;
        state.byUserID = byUserID;
      })
      .addDefaultCase(() => {});
  },
});

function formatUserPreference({
  preference,
  ...userPreference
}: DecoratedUserPreference) {
  if (userPreference.preferenceID === PREFERENCE_IDS.WEEK_STARTS_ON) {
    return { ...userPreference, value: parseInt(userPreference.value) };
  } else {
    return userPreference;
  }
}

export function buildUserPreferenceKey(
  userID: Identifier,
  preferenceID: Identifier
) {
  return `${userID}.${preferenceID}`;
}

export default userPreferencesSlice.reducer;
