export const PREFERENCE_IDS = {
    CURRENCY: 1,
    PROFILE_PIC_SOURCE: 2,
    GOOGLE_ADS_CUSTOMER_ID: 3,
    TIMEZONE: 4,
    GOOGLE_ANALYTICS_VIEW_ID: 5,
    FACEBOOK_AD_ACCOUNT_ID: 6,
    FACEBOOK_PAGE_ID: 7,
    LOGO: 8,
    BUTTON_BG_COLOR: 9,
    BUTTON_TEXT_COLOR: 10,
    TEXT_COLOR: 11,
    WEEK_STARTS_ON: 12,
    DATE_FORMAT: 13,
} as const;
