import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SetActiveAccountResponse } from '@witmetrics/api-client';
import { setCurrentUser } from './currentUserSlice';

type ActiveAccount = SetActiveAccountResponse['activeAccount'];

type ActiveAccountState = ActiveAccount | null;

const initialState = null satisfies ActiveAccountState as ActiveAccountState;

export const activeAccountSlice = createSlice({
  name: 'activeAccount',
  initialState,
  reducers: {
    setActiveAccount: (state, action: PayloadAction<ActiveAccount>) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setCurrentUser, (state, action) => {
        return action.payload.activeAccount || state;
      })
      .addDefaultCase(() => {});
  },
});

export const { setActiveAccount } = activeAccountSlice.actions;

export default activeAccountSlice.reducer;
