import { DIALOGS } from '@/constants/dialogs';
import { OVERLAYS } from '@/constants/overlays';
import { useSessionStorage } from '@/hooks/useStorage';
import type { Dictionary } from '@/types';

export const DEFAULT_DIALOGS: Dictionary<boolean> = {
  [DIALOGS.ACCOUNTS]: false,
  [DIALOGS.LOGIN]: false,
  [DIALOGS.SEARCH]: false,
  [DIALOGS.SHORTCUTS]: false,
};

export const DEFAULT_OVERLAYS: Dictionary<boolean> = {
  [OVERLAYS.AGENDA]: false,
};

export function useStoredAccountID() {
  return useSessionStorage<number | null>('activeAccountID', null, true);
}
