import { createSlice } from '@reduxjs/toolkit';
import {
  FetchCurrentUserResponse,
  UndecoratedUser,
} from '@witmetrics/api-client';
import { SetCurrentUserAction } from '../utils/sharedActions';

type CurrentUserState = UndecoratedUser | null;

const initialState = null satisfies CurrentUserState as CurrentUserState;

export const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    setCurrentUser: (state, action: SetCurrentUserAction) => {
      return getFlatUser(action.payload);
    },
  },
});

function getFlatUser({
  userPractices,
  userOrganizations,
  activeAccount,
  tasks,
  userPermissions,
  userPracticePermissions,
  userOrganizationPermissions,
  userPreferences,
  ...flatUser
}: FetchCurrentUserResponse) {
  return flatUser;
}

export const { setCurrentUser } = currentUserSlice.actions;

export default currentUserSlice.reducer;
