'use client';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import type { Dictionary } from '@/types';
import { useEffect, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';

type Params = Dictionary<any>;

export function usePath() {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [currentParams, setCurrentParams] = useState(
    Object.fromEntries(searchParams.entries()) as Params
  );
  const oldParams = useRef(
    Object.fromEntries(searchParams.entries()) as Params
  );

  useEffect(() => {
    if (!isEqual(oldParams.current, currentParams)) {
      router.push(
        `${pathname}/?${new URLSearchParams(currentParams).toString()}`
      );
      oldParams.current = currentParams;
    }
  }, [currentParams]);

  const pushParams = (params: Params) => {
    return setCurrentParams({
      ...Object.fromEntries(searchParams.entries()),
      ...params,
    });
  };

  const replaceParams = (params: Params) => {
    return setCurrentParams(params);
  };

  const removeParams = (paramKeys: string[] = []) => {
    const params = Object.fromEntries(searchParams.entries());
    paramKeys.forEach((key) => {
      delete params[key];
    });
    setCurrentParams(params);
  };

  const navigate = (path: string, params?: Params) => {
    const base = path.endsWith('/') ? path : `${path}/`;
    if (params) {
      router.push(`${base}?${new URLSearchParams(params).toString()}`);
    } else {
      router.push(`${base}`);
    }
  };

  return {
    router,
    pathname,
    searchParams,
    pushParams,
    replaceParams,
    removeParams,
    navigate,
  } as const;
}
