import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { DecoratedConversationMessage } from '@witmetrics/api-client';
import { setActiveAccount } from './activeAccountSlice';
import { deleteConversation } from './conversationsSlice';
import { addToIDArray } from '../utils/stateFormatting';

type ConversationMessagesState = {
  byID: Record<string, DecoratedConversationMessage>;
  byConversationID: Record<string, string[]>;
};

const initialState = {
  byID: {},
  byConversationID: {},
} satisfies ConversationMessagesState as ConversationMessagesState;

export const conversationMessagesSlice = createSlice({
  name: 'conversationMessages',
  initialState,
  reducers: {
    setConversationMessages: (
      state,
      action: PayloadAction<DecoratedConversationMessage[]>
    ) => {
      return getNextState(state, action.payload);
    },
    addConversationMessage: (
      state,
      action: PayloadAction<DecoratedConversationMessage>
    ) => {
      return getNextState(state, [action.payload]);
    },
    updateConversationMessage: (
      state,
      action: PayloadAction<DecoratedConversationMessage>
    ) => {
      return getNextState(state, [action.payload]);
    },
    deleteConversationMessage: (
      state,
      action: PayloadAction<{ conversationMessageID: number }>
    ) => {
      const key = `${action.payload.conversationMessageID}`;
      if (!state.byID[key]) return state;
      const { conversationID } = state.byID[key];
      if (state.byConversationID[conversationID]) {
        state.byConversationID[conversationID] = state.byConversationID[
          conversationID
        ].filter((id) => id !== key);
      }
      delete state.byID[key];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => {
        return { byID: {}, byConversationID: {} };
      })
      .addCase(deleteConversation, (state, action) => {
        const messageIDs =
          state.byConversationID[action.payload.conversationID];
        if (messageIDs) {
          messageIDs.forEach((id) => {
            delete state.byID[id];
          });
          delete state.byConversationID[action.payload.conversationID];
        }
      })
      .addDefaultCase(() => {});
  },
});

function getNextState(
  state: ConversationMessagesState,
  messages: DecoratedConversationMessage[]
) {
  let byID = { ...state.byID };
  let byConversationID = { ...state.byConversationID };
  messages.forEach((m) => {
    const key = `${m.id}`;
    byID[key] = m;
    byConversationID[m.conversationID] = addToIDArray(
      byConversationID[m.conversationID],
      key
    );
  });
  return { byID, byConversationID };
}

export const {
  setConversationMessages,
  addConversationMessage,
  updateConversationMessage,
  deleteConversationMessage,
} = conversationMessagesSlice.actions;

export default conversationMessagesSlice.reducer;
