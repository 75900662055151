import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type {
  DecoratedChecklistItem,
  DecoratedUnisonProjectChecklist,
} from '@witmetrics/api-client';
import { setActiveAccount } from './activeAccountSlice';
import { setUnisonProjectChecklists } from './unisonProjectChecklistsSlice';
import { addChecklist } from './checklistsSlice';
import { addToIDArray } from '@/store/utils/stateFormatting';

type ChecklistItemsState = {
  byID: Record<string, DecoratedChecklistItem>;
  byChecklistID: Record<string, string[]>;
};

const initialState = {
  byID: {},
  byChecklistID: {},
} satisfies ChecklistItemsState as ChecklistItemsState;

export const checklistItemsSlice = createSlice({
  name: 'checklistItems',
  initialState,
  reducers: {
    setChecklistItems: (
      state,
      action: PayloadAction<DecoratedChecklistItem[]>
    ) => {
      const checklistItems = action.payload.map(getFlatChecklistItem);
      return getNextState(state, checklistItems);
    },
    addChecklistItem: (
      state,
      action: PayloadAction<DecoratedChecklistItem>
    ) => {
      return getNextState(state, [action.payload]);
    },
    updateChecklistItem: (
      state,
      action: PayloadAction<DecoratedChecklistItem>
    ) => {
      return getNextState(state, [action.payload]);
    },
    deleteChecklistItem: (state, action: PayloadAction<{ itemID: number }>) => {
      const key = `${action.payload.itemID}`;
      if (!state.byID[key]) return state;
      const { checklistID } = state.byID[key];
      if (state.byChecklistID[checklistID]) {
        state.byChecklistID[checklistID] = state.byChecklistID[
          checklistID
        ].filter((id) => id !== key);
      }
      delete state.byID[key];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => {
        return { byID: {}, byChecklistID: {} };
      })
      .addCase(setUnisonProjectChecklists, (state, action) => {
        return getNextState(
          state,
          parseUnisonProjectChecklists(action.payload)
        );
      })
      .addCase(addChecklist, (state, action) => {
        return getNextState(
          state,
          action.payload.items.map(getFlatChecklistItem)
        );
      })
      .addDefaultCase(() => {});
  },
});

function getFlatChecklistItem(checklistItem: DecoratedChecklistItem) {
  // TODO: Pull assignedTo out to usersSlice?
  return checklistItem;
}

function parseUnisonProjectChecklists(
  unisonProjectChecklists: DecoratedUnisonProjectChecklist[]
) {
  return unisonProjectChecklists.reduce(
    (a: DecoratedChecklistItem[], { checklist: { items } }) => {
      return [...a, ...items];
    },
    []
  );
}

function getNextState(
  state: ChecklistItemsState,
  checklistItems: DecoratedChecklistItem[]
) {
  let byID = { ...state.byID };
  let byChecklistID = { ...state.byChecklistID };
  checklistItems.forEach((ci) => {
    const key = `${ci.id}`;
    byID[key] = getFlatChecklistItem(ci);
    byChecklistID[ci.checklistID] = addToIDArray(
      byChecklistID[ci.checklistID],
      key
    );
  });
  return { byID, byChecklistID };
}

export const {
  setChecklistItems,
  addChecklistItem,
  updateChecklistItem,
  deleteChecklistItem,
} = checklistItemsSlice.actions;

export default checklistItemsSlice.reducer;
