import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type {
  DecoratedFileCollectionFile,
  UndecoratedFile,
} from '@witmetrics/api-client';
import { setActiveAccount } from './activeAccountSlice';
import { setFileCollectionFiles } from './fileCollectionFilesSlice';
import {
  addConversationMessage,
  setConversationMessages,
} from './conversationMessagesSlice';
import { mapToKey } from '@/utils/arrays';

type FilesState = {
  byID: Record<string, UndecoratedFile>;
};

const initialState = {
  byID: {},
} satisfies FilesState as FilesState;

export const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    setFiles: (state, action: PayloadAction<UndecoratedFile[]>) => {
      return getNextState(state, action.payload);
    },
    addFile: (state, action: PayloadAction<UndecoratedFile>) => {
      return getNextState(state, [action.payload]);
    },
    updateFile: (state, action: PayloadAction<UndecoratedFile>) => {
      return getNextState(state, [action.payload]);
    },
    deleteFile: (state, action: PayloadAction<{ fileID: number }>) => {
      delete state.byID[action.payload.fileID];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => {
        return { byID: {} };
      })
      .addCase(setFileCollectionFiles, (state, action) => {
        return getNextState(state, flattenFileCollectionFiles(action.payload));
      })
      .addCase(setConversationMessages, (state, action) => {
        return getNextState(
          state,
          action.payload.flatMap((m) => m.files.flatMap((f) => f.file))
        );
      })
      .addCase(addConversationMessage, (state, action) => {
        return getNextState(
          state,
          action.payload.files.flatMap((f) => f.file)
        );
      })
      .addDefaultCase(() => {});
  },
});

function flattenFileCollectionFiles(
  fileCollectionFiles: DecoratedFileCollectionFile[]
) {
  return mapToKey(fileCollectionFiles, 'file');
}

function getNextState(state: FilesState, files: UndecoratedFile[]) {
  let byID = { ...state.byID };
  files.forEach((f) => {
    const key = `${f.id}`;
    byID[key] = f;
  });
  return { byID };
}

export const { setFiles, addFile, updateFile, deleteFile } = filesSlice.actions;

export default filesSlice.reducer;
