import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setActiveAccount } from './activeAccountSlice';
import {
  DecoratedFileCollectionFile,
  UndecoratedFileCollectionFile,
} from '@witmetrics/api-client';
import { addToIDArray } from '../utils/stateFormatting';

type FileCollectionFilesState = {
  byID: Record<string, UndecoratedFileCollectionFile>;
  byFileCollectionID: Record<string, string[]>;
};

const initialState = {
  byID: {},
  byFileCollectionID: {},
} satisfies FileCollectionFilesState as FileCollectionFilesState;

export const fileCollectionFilesSlice = createSlice({
  name: 'fileCollectionFiles',
  initialState,
  reducers: {
    setFileCollectionFiles: (
      state,
      action: PayloadAction<DecoratedFileCollectionFile[]>
    ) => {
      return getNextState(state, action.payload);
    },
    addFileCollectionFile: (
      state,
      action: PayloadAction<DecoratedFileCollectionFile>
    ) => {
      return getNextState(state, [action.payload]);
    },
    deleteFileCollectionFile: (
      state,
      action: PayloadAction<{ fileCollectionID: number; fileID: number }>
    ) => {
      const key = buildFileCollectionFileKey(
        action.payload.fileCollectionID,
        action.payload.fileID
      );
      if (!state.byID[key]) return state;
      const fileCollectionID = action.payload.fileCollectionID;
      if (state.byFileCollectionID[fileCollectionID]) {
        state.byFileCollectionID[fileCollectionID] = state.byFileCollectionID[
          fileCollectionID
        ].filter((id) => {
          return id !== key;
        });
      }
      delete state.byID[key];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => {
        return { byID: {}, byFileCollectionID: {} };
      })
      .addDefaultCase(() => {});
  },
});

function formatFileCollectionFile({
  file,
  ...fileCollectionFile
}: DecoratedFileCollectionFile) {
  return fileCollectionFile;
}

function getNextState(
  state: FileCollectionFilesState,
  fileCollectionFiles: DecoratedFileCollectionFile[]
) {
  let byID = { ...state.byID };
  let byFileCollectionID = { ...state.byFileCollectionID };
  fileCollectionFiles.forEach((fcf) => {
    const key = buildFileCollectionFileKey(fcf.fileCollectionID, fcf.fileID);
    byID[key] = formatFileCollectionFile(fcf);
    byFileCollectionID[fcf.fileCollectionID] = addToIDArray(
      byFileCollectionID[fcf.fileCollectionID],
      key
    );
  });
  return { byID, byFileCollectionID };
}

export function buildFileCollectionFileKey(
  fileCollectionID: number,
  fileID: number
) {
  return `${fileCollectionID}.${fileID}`;
}

export const {
  setFileCollectionFiles,
  addFileCollectionFile,
  deleteFileCollectionFile,
} = fileCollectionFilesSlice.actions;

export default fileCollectionFilesSlice.reducer;
