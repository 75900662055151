import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type {
  DecoratedChecklist,
  UndecoratedChecklist,
} from '@witmetrics/api-client';
import { setActiveAccount } from './activeAccountSlice';
import { setUnisonProjectChecklists } from './unisonProjectChecklistsSlice';
import { mapToKey } from '@/utils/arrays';

type ChecklistsState = {
  byID: Record<string, UndecoratedChecklist>;
};

const initialState = {
  byID: {},
} satisfies ChecklistsState as ChecklistsState;

export const checklistsSlice = createSlice({
  name: 'checklists',
  initialState,
  reducers: {
    setChecklists: (state, action: PayloadAction<DecoratedChecklist[]>) => {
      return getNextState(state, action.payload);
    },
    addChecklist: (state, action: PayloadAction<DecoratedChecklist>) => {
      return getNextState(state, [action.payload]);
    },
    updateChecklist: (state, action: PayloadAction<DecoratedChecklist>) => {
      return getNextState(state, [action.payload]);
    },
    deleteChecklist: (
      state,
      action: PayloadAction<{ checklistID: number }>
    ) => {
      delete state.byID[action.payload.checklistID];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => {
        return { byID: {} };
      })
      .addCase(setUnisonProjectChecklists, (state, action) => {
        return getNextState(state, mapToKey(action.payload, 'checklist'));
      })
      .addDefaultCase(() => {});
  },
});

function getFlatChecklist({ items, ...checklist }: DecoratedChecklist) {
  return checklist;
}

function getNextState(
  state: ChecklistsState,
  checklists: DecoratedChecklist[]
) {
  let byID = { ...state.byID };
  checklists.forEach((c) => {
    const key = `${c.id}`;
    byID[key] = getFlatChecklist(c);
  });
  return { byID };
}

export const { setChecklists, addChecklist, updateChecklist, deleteChecklist } =
  checklistsSlice.actions;

export default checklistsSlice.reducer;
