import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setActiveAccount } from './activeAccountSlice';
import { deleteTask } from './tasksSlice';
import {
  DecoratedUnisonProjectTask,
  UndecoratedUnisonProjectTask,
} from '@witmetrics/api-client';
import { addToIDArray } from '../utils/stateFormatting';

type UnisonProjectTasksState = {
  byID: Record<string, UndecoratedUnisonProjectTask>;
  byTaskID: Record<string, string>;
  byUnisonProjectID: Record<string, string[]>;
};

const initialState = {
  byID: {},
  byTaskID: {},
  byUnisonProjectID: {},
} satisfies UnisonProjectTasksState as UnisonProjectTasksState;

export const unisonProjectTasksSlice = createSlice({
  name: 'unisonProjectTasks',
  initialState,
  reducers: {
    setUnisonProjectTasks: (
      state,
      action: PayloadAction<DecoratedUnisonProjectTask[]>
    ) => {
      return getNextState(state, action.payload);
    },
    addUnisonProjectTask: (
      state,
      action: PayloadAction<DecoratedUnisonProjectTask>
    ) => {
      return getNextState(state, [action.payload]);
    },
    updateUnisonProjectTask: (
      state,
      action: PayloadAction<DecoratedUnisonProjectTask>
    ) => {
      return getNextState(state, [action.payload]);
    },
    deleteUnisonProjectTask: (
      state,
      action: PayloadAction<{ unisonProjectID: number; taskID: number }>
    ) => {
      const key = buildUnisonProjectTaskKey(
        action.payload.unisonProjectID,
        action.payload.taskID
      );
      if (!state.byID[key]) return state;
      const projectID = action.payload.unisonProjectID;
      if (state.byUnisonProjectID[projectID]) {
        state.byUnisonProjectID[projectID] = state.byUnisonProjectID[
          projectID
        ].filter((id) => {
          return id !== key;
        });
      }
      delete state.byID[key];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => {
        return { byID: {}, byTaskID: {}, byUnisonProjectID: {} };
      })
      .addCase(deleteTask, (state, action) => {
        let byID = { ...state.byID };
        let byTaskID = { ...state.byTaskID };
        let byUnisonProjectID = { ...state.byUnisonProjectID };
        Object.keys(byID).forEach((key) => {
          if (byID[key].taskID === action.payload.taskID) {
            delete byID[key];
          }
        });
        Object.keys(byUnisonProjectID).forEach((projectID) => {
          byUnisonProjectID[projectID] = byUnisonProjectID[projectID].filter(
            (key) => {
              return byID[key] !== undefined;
            }
          );
        });
        delete byTaskID[action.payload.taskID];
        return { byID, byTaskID, byUnisonProjectID };
      })
      .addDefaultCase(() => {});
  },
});

function formatUnisonProjectTask({
  task,
  ...unisonProjectTask
}: DecoratedUnisonProjectTask) {
  return unisonProjectTask;
}

function getNextState(
  state: UnisonProjectTasksState,
  unisonProjectTasks: DecoratedUnisonProjectTask[]
) {
  let byID = { ...state.byID };
  let byTaskID = { ...state.byTaskID };
  let byUnisonProjectID = { ...state.byUnisonProjectID };
  unisonProjectTasks.forEach((upt) => {
    const key = buildUnisonProjectTaskKey(upt.unisonProjectID, upt.taskID);
    byID[key] = formatUnisonProjectTask(upt);
    byTaskID[upt.taskID] = key;
    byUnisonProjectID[upt.unisonProjectID] = addToIDArray(
      byUnisonProjectID[upt.unisonProjectID],
      key
    );
  });
  return { byID, byTaskID, byUnisonProjectID };
}

export function buildUnisonProjectTaskKey(
  unisonProjectID: number,
  taskID: number
) {
  return `${unisonProjectID}.${taskID}`;
}

export const {
  setUnisonProjectTasks,
  addUnisonProjectTask,
  updateUnisonProjectTask,
  deleteUnisonProjectTask,
} = unisonProjectTasksSlice.actions;

export default unisonProjectTasksSlice.reducer;
