export type StorageType = 'localStorage' | 'sessionStorage';

function isJSON(type: string) {
  return type === 'object' || type === 'array';
}

export function stringifyValue(type: string, value: any) {
  return isJSON(type) ? JSON.stringify(value) : value.toString();
}

export function parseValue(type: string, value: any) {
  if (isJSON(type)) {
    return JSON.parse(value);
  } else if (type === 'number') {
    return Number(value);
  } else if (type === 'boolean') {
    return value === 'true';
  } else return value;
}

export function getType(value: any) {
  if (Array.isArray(value)) return 'array';
  else return typeof value;
}

export function getInitialValue<T>(
  storage: StorageType,
  key: string,
  initialValue: T,
  useExisting?: boolean
) {
  try {
    if (!useExisting || !window[storage].getItem(key)) return initialValue;
    const { type, value } = JSON.parse(window[storage].getItem(key)!);
    return parseValue(type, value) as T;
  } catch (err) {
    // NOTE: The SSR render will throw a "window is not defined" error which is expected and ignorable
    if (err instanceof Error && !err.message.includes('window')) {
      console.warn(`Error parsing stored value: "${key}" from ${storage}`);
      throw err;
    }
  }
}
