import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setActiveAccount } from './activeAccountSlice';
import { deleteConversation, setConversations } from './conversationsSlice';
import {
  DecoratedConversationUnisonProject,
  DecoratedUnisonProjectConversation,
  UndecoratedUnisonProjectConversation,
} from '@witmetrics/api-client';
import { addToIDArray } from '../utils/stateFormatting';

type RawUnisonProjectConversation = Omit<
  DecoratedUnisonProjectConversation,
  'conversation'
> & {
  conversation?: DecoratedUnisonProjectConversation['conversation'];
  unisonProject?: DecoratedConversationUnisonProject['unisonProject'];
};

type UnisonProjectConversationsState = {
  byID: Record<string, UndecoratedUnisonProjectConversation>;
  byUnisonProjectID: Record<string, string[]>;
  byConversationID: Record<string, string[]>;
};

const initialState = {
  byID: {},
  byUnisonProjectID: {},
  byConversationID: {},
} satisfies UnisonProjectConversationsState as UnisonProjectConversationsState;

export const unisonProjectConversationsSlice = createSlice({
  name: 'unisonProjectConversations',
  initialState,
  reducers: {
    setUnisonProjectConversations: (
      state,
      action: PayloadAction<DecoratedUnisonProjectConversation[]>
    ) => {
      return getNextState(state, action.payload);
    },
    addUnisonProjectConversation: (
      state,
      action: PayloadAction<DecoratedUnisonProjectConversation>
    ) => {
      return getNextState(state, [action.payload]);
    },
    updateUnisonProjectConversation: (
      state,
      action: PayloadAction<DecoratedUnisonProjectConversation>
    ) => {
      return getNextState(state, [action.payload]);
    },
    deleteUnisonProjectConversation: (
      state,
      action: PayloadAction<{ unisonProjectID: number; conversationID: number }>
    ) => {
      const key = buildUnisonProjectConversationKey(
        action.payload.unisonProjectID,
        action.payload.conversationID
      );
      if (!state.byID[key]) return state;
      const projectID = action.payload.unisonProjectID;
      if (state.byUnisonProjectID[projectID]) {
        state.byUnisonProjectID[projectID] = state.byUnisonProjectID[
          projectID
        ].filter((id) => {
          return id !== key;
        });
      }
      delete state.byID[key];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => {
        return { byID: {}, byUnisonProjectID: {}, byConversationID: {} };
      })
      .addCase(setConversations, (state, action) => {
        return getNextState(
          state,
          action.payload.flatMap(({ unisonProjects }) =>
            unisonProjects.map(formatUnisonProjectConversation)
          )
        );
      })
      .addCase(deleteConversation, (state, action) => {
        let byID = { ...state.byID };
        let byUnisonProjectID = { ...state.byUnisonProjectID };
        let byConversationID = { ...state.byConversationID };
        Object.keys(byID).forEach((key) => {
          if (byID[key].conversationID === action.payload.conversationID) {
            delete byID[key];
          }
        });
        Object.keys(byUnisonProjectID).forEach((projectID) => {
          byUnisonProjectID[projectID] = byUnisonProjectID[projectID].filter(
            (key) => {
              return byID[key] !== undefined;
            }
          );
        });
        delete byConversationID[action.payload.conversationID];
        return { byID, byUnisonProjectID, byConversationID };
      })
      .addDefaultCase(() => {});
  },
});

function formatUnisonProjectConversation({
  conversation,
  unisonProject,
  ...unisonProjectConversation
}: RawUnisonProjectConversation) {
  return unisonProjectConversation;
}

function getNextState(
  state: UnisonProjectConversationsState,
  unisonProjectConversations: RawUnisonProjectConversation[]
) {
  let byID = { ...state.byID };
  let byUnisonProjectID = { ...state.byUnisonProjectID };
  let byConversationID = { ...state.byConversationID };
  unisonProjectConversations.forEach((upc) => {
    const key = buildUnisonProjectConversationKey(
      upc.unisonProjectID,
      upc.conversationID
    );
    byID[key] = formatUnisonProjectConversation(upc);
    byUnisonProjectID[upc.unisonProjectID] = addToIDArray(
      byUnisonProjectID[upc.unisonProjectID],
      key
    );
    byConversationID[upc.conversationID] = addToIDArray(
      byConversationID[upc.conversationID],
      key
    );
  });
  return { byID, byUnisonProjectID, byConversationID };
}

export function buildUnisonProjectConversationKey(
  unisonProjectID: number,
  conversationID: number
) {
  return `${unisonProjectID}.${conversationID}`;
}

export function parseUnisonProjectConversationKey(key: string): {
  unisonProjectID: number;
  conversationID: number;
} {
  const keys = key.split('.');
  return {
    unisonProjectID: parseInt(keys[0]),
    conversationID: parseInt(keys[1]),
  };
}

export const {
  setUnisonProjectConversations,
  addUnisonProjectConversation,
  updateUnisonProjectConversation,
  deleteUnisonProjectConversation,
} = unisonProjectConversationsSlice.actions;

export default unisonProjectConversationsSlice.reducer;
